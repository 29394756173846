<template>
	<b-card no-body>
		<b-card-header>
			<b-card-title>
				{{$t('Schedule Name')}}: Test
			</b-card-title>
		</b-card-header>
		<b-card-body>
			<b-table
				ref="priceList"
				hover
				:items="dataList"
				:fields="tableColumns"
				primary-key="id"
				show-empty
				:empty-text="$t('No Data')"
				stacked="sm"
				
			>
				<!-- Column: range -->
				<template #cell(range)="data">
					{{data.item.begin}} 至 {{data.item.end}}
				</template>
			</b-table>
		</b-card-body>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BImg, BTable, BTooltip } from 'bootstrap-vue'
export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,BCardBody,
		BCardTitle,
		BTable,
		BTooltip,
	},

	data () {
		return {
		tableColumns: [
			{ key: 'id', },
			{ key: 'name', label: this.$t('Name') },
			{ key: 'range', label: this.$t('Date Range') },
			{ key: 'cycle', label: this.$t('Cycle') },
		],
		dataList: [],
		isNewSidebarActive: false,
		}
	},
	created(){
		this.getData()
	},
	methods: {
		getData () {
			this.dataList = [{
				id:1, name: '测试屏幕',cycle: '每天', begin: "2022-09-09", end: "2022-09-10", price: "10"
			},{
				id:2, name: '测试屏幕',cycle: '每天', begin: "2022-09-09", end: "2022-09-10", price: "10"
			},{
				id:3, name: '测试屏幕',cycle: '每天', begin: "2022-09-09", end: "2022-09-10", price: "10"
			},{
				id:4, name: '测试屏幕',cycle: '每天', begin: "2022-09-09", end: "2022-09-10", price: "10"
			}]
		},
		newAction () {
			this.isNewSidebarActive = true
		},
		editAction(item){
			this.isNewSidebarActive = true
		},
		deleteAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
				title: this.$t('Tips'),//'Please Confirm',
				size: 'sm',
				okVariant: 'danger',
				okTitle: this.$t('Delete'),
				cancelTitle: this.$t('Cancel'),
				cancelVariant: 'outline-secondary',
				hideHeaderClose: false,
				centered: true,
			})
			.then(value => {
			// 
			})
		}
	}
}
</script>